<template>

  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="mt-1">
            {{ accountId || '--' }}
          </h4>

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">

            <v-select
              v-model="typeFilter"
              :options="typeOptions"
              class="invoice-filter-select mr-5"
              placeholder="Select type"
              @input="loadLogs(accountId)"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>

            <v-select
              v-model="statusFilter"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
              @input="loadLogs(accountId)"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-overlay
      :show="loading"
      rounded="sm"
    >

      <b-table
        striped
        hover
        :items="logs"
        :fields="fields"
      >

        <template #cell(id)="data">
          <span v-b-tooltip.hover.top="data.item.id">
            #
          </span>
        </template>

        <template #cell(status)="data">

          <b-badge :variant="data.item.status== 'error' ? 'danger' : data.item.status">
            {{ data.item.status }}
          </b-badge>

        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt|formatDateTime }}
        </template>

      </b-table>

      <div
        v-if="rows > 25"
        class="mt-1 d-flex justify-content-center"
      >
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePageChange"
        />
      </div>

    </b-overlay>

  </b-card>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BButton, BPagination, VBTooltip, BOverlay, BRow, BCol, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    vSelect,
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      accountId: null,
      searchQuery: null,
      statusFilter: null,
      typeFilter: null,
      statusOptions: [
        'success',
        'error',
        'warning',
      ],
      typeOptions: [
        'amz_ads_report_request',
        'amz_ads_report_download',
      ],
      logs: [],
      perPage: 25,
      fields: ['id', 'label', 'key', 'status', 'details', 'createdAt'],
      current: 1,
      rows: 0,
    }
  },

  mounted() {
    this.accountId = this.$route.params.accountId
    this.loadLogs(this.$route.params.accountId, 1)
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadLogs(this.accountId, this.page)
    },

    loadLogs(accountId, page = 1) {
      const filters = {}

      if (this.typeFilter) {
        filters.key = this.typeFilter
      }

      if (this.statusFilter) {
        filters.status = this.statusFilter
      }

      this.loading = true
      this.$store.dispatch('amzAds/fetchLogs', { accountId, page, ...filters })
        .then(result => {
          this.logs = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          console.log('Finally')

          this.loading = false
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 120px;
    }
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
